import { AMO_CFTYPE } from "./amo-type-field";
import { TSmartButtonAmoFieldTypes, TSmartButtonEnums } from "../types/smart-button-settings.types";

export const amoFieldTypes: TSmartButtonAmoFieldTypes = {
    [AMO_CFTYPE.TEXT]: 'input',
    [AMO_CFTYPE.NUMERIC]: 'input',
    [AMO_CFTYPE.CHECKBOX]: 'input',
    [AMO_CFTYPE.SELECT]: 'select',
    [AMO_CFTYPE.MULTISELECT]: 'select-checkbox',
    [AMO_CFTYPE.DATE]: 'input',
    [AMO_CFTYPE.URL]: 'input',
    [AMO_CFTYPE.MULTITEXT]: 'input',
    [AMO_CFTYPE.TEXTAREA]: 'input',
    [AMO_CFTYPE.RADIOBUTTON]: 'input',
    // [AMO_CFTYPE.STREETADDRESS]: 'input',
    // [AMO_CFTYPE.SMART_ADDRESS]: 'input',
    [AMO_CFTYPE.BIRTHDAY]: 'input',
    [AMO_CFTYPE.LEGAL_ENTITY]: 'input',
    // [AMO_CFTYPE.CATALOG_ITEMS]: 'input',
    [AMO_CFTYPE.ORGANIZATION]: 'input',
    // [AMO_CFTYPE.CATALOG_GROUP]: 'input',
    [AMO_CFTYPE.DATETIME]: 'input',
    [AMO_CFTYPE.CATALOG_PRICE]: 'input',
    [AMO_CFTYPE.TRACKING_DATA]: 'input',
    // [AMO_CFTYPE.CHAINED_LINK]: 'input',
    [AMO_CFTYPE.MONETARY]: 'input',
    // [AMO_CFTYPE.CHAINED_LIST]: 'input',
    // [AMO_CFTYPE.FILE]: 'input',
    [AMO_CFTYPE.SELECT_STATUS]: 'status-select',
}

export const enumsSmartButtons: TSmartButtonEnums = {
    defaultOperators: [
        {
            id: 'equals',
            label: 'Равно',
            value: '='
        },
        {
            id: 'not_equal',
            label: 'Не равно',
            value: '!='
        },
        {
            id: 'more',
            label: 'Больше',
            value: '>'
        },
        {
            id: 'less',
            label: 'Меньше',
            value: '<'
        },
        {
            id: 'greater_or_equal',
            label: 'Больше или равно',
            value: '>='
        },
        {
            id: 'less_or_equal',
            label: 'Меньше или равно',
            value: '<='
        },
        {
            id: 'contains',
            label: 'Содержит',
            value: 'contains'
        },
        {
            id: 'not_contains',
            label: 'Не содержит',
            value: 'not_contains'
        },
    ],
    statusSelectOperators: [
        {
            id: 'equals',
            label: 'Равно',
            value: '='
        },
        {
            id: 'not_equal',
            label: 'Не равно',
            value: '!='
        },
        {
            id: 'contains',
            label: 'Содержит',
            value: 'contains'
        },
        {
            id: 'not_contains',
            label: 'Не содержит',
            value: 'not_contains'
        },
    ]
}
