export const AMO_CFTYPE = {
    TEXT           : 1,   // "Текст": Обычное текстовое поле
    NUMERIC        : 2,   // "Число": Текстовое поле с возможностью передавать только цифры
    CHECKBOX       : 3,   // "Флаг": Поле обозначающее только наличие или отсутствие свойства (например: "да"/"нет")
    SELECT         : 4,   // "Список": Поле типа список с возможностью выбора одного элемента
    MULTISELECT    : 5,   // "Мультисписок": Поле типа список c возможностью выбора нескольких элементов списка
    DATE           : 6,   // "Дата": Поле типа дата возвращает и принимает значения в формате (Y-m-d H:i:s)
    URL            : 7,   // "Ссылка": Обычное текстовое поле предназначенное для ввода URL адресов      [формат как у TEXT]
    MULTITEXT      : 8,   // "Мультитекст"? [type_id 8 используется в PHONE/EMAIL полях контактов и компаний]
    TEXTAREA       : 9,   // "Текстовая область": Поле textarea содержащее большое количество текста
    RADIOBUTTON    : 10,   // "Переключатель": Поле типа переключатель, выбор одного из нескольких вариантов     [формат как у SELECT]
    STREETADDRESS  : 11,   // "Короткий адрес": Короткое поле адрес, одна строка         [формат как у TEXTAREA]
    SMART_ADDRESS  : 13,   // "Адрес": Многострочное поле адрес (в интерфейсе является набором из нескольких полей)
    BIRTHDAY       : 14,   // "День рождения": Поле типа дата, поиск по которому в amoCRM осуществляется без учета года, значения в формате (Y-m-d H:i:s)
    LEGAL_ENTITY   : 15,   // "Юр. лицо": Поле юридическое лицо (в интерфейсе является набором из нескольких полей)
    CATALOG_ITEMS  : 16,   // (Каталог) "Предметы": Поле "Cостав" каталога (поле доступно только в пользовательских списках)
    ORGANIZATION   : 17,   // Поле организация, в js-списке APP.cf_types названо как "org_legal_name"
    CATALOG_GROUP  : 18,   // (Каталог) "Категория": Поле "Группа" товара (в каталогах товаров)
    DATETIME       : 19,   // "Дата и время": Поле типа дата/время: значения в формате (Y-m-d H:i:s)
    CATALOG_PRICE  : 20,   // (Каталог) "Цена": Поле "Цена" товара (в каталогах товаров)
    TRACKING_DATA  : 21,   // (Сделка) "Отслеживаемые данные": Поля "Метки систем статистики" сделки (вкладка "Статистика") [201223: новый тип]
    CHAINED_LINK   : 22,   // (Каталог) "Связь с другим элементом": Поля "Связь с другими элементами" используются в CHAINED_LIST
    MONETARY       : 23,   // (платная опция Супер-поля) "Денежное": Поле "Денежное поле", идентичное полю "Бюджет" (весна 2022)
    CHAINED_LIST   : 24,   // (платная опция Супер-поля) "Каталоги и списки": Поле "Супер-поле" с возможностью выбора одного элемента и группы из элементов
    FILE           : 25,   // "Файл": Поле для хранения файла с версиями, с возможностью вернуться к предыдущей версии, скачать файл, заменить и удалить (весна 2022)
    PAYER          : 26,   // (Каталог/Счета-покупки) "Плательщик": Поле в списке счетов/покупок (весна 2023)
    SELECT_STATUS  : 77    // (Кастомное поле выбрать статус)
}
