import {
    TStatusSelectOptionsParameters,
    TStatusSelectOptionsPipeline
} from "../../../libs-select/status-select/models/status-select-options.types";
import {
    TAmoOptionsSelect,
    TSmartButtonData,
    TSmartButtonDataItemsObject, TSmartButtonDataValues, TSmartButtonKey,
    TSmartButtonLeadField
} from "../../types/smart-button-settings.types";

export class SmartButtonData {
    public users: TSmartButtonLeadField[] = [];
    public leadFields: TSmartButtonLeadField[] = [];
    public statuses: TStatusSelectOptionsParameters<TStatusSelectOptionsPipeline> = {};
    public items_object: TSmartButtonDataItemsObject = {
        leadFields: {},
        users: {},
        statuses: {},
    };

    constructor(data: TSmartButtonData | null) {
        if (data === null) {
            return;
        }

        Object.defineProperty(this, 'items_object', {
            enumerable: false,
            configurable: false,
            writable: false,
            value: {
                'statuses': {},
                'users': {},
                'leadFields': {},
            }
        });

        this.users = data.users;
        this.leadFields = data.leadFields;
        this.statuses = data.statuses;

        for (const key in data) {
            if (key === 'users' || key === 'leadFields' || key === 'statuses') {
                (this[key as TSmartButtonKey] as TSmartButtonDataValues) = data[key];

                const items: TSmartButtonDataValues = this[key as TSmartButtonKey] as TSmartButtonDataValues;
                if (items) {
                    if (key === 'users' || key === 'leadFields') {

                        this.setItemsObject(data[key], (key as TSmartButtonKey));

                    } else if (key === 'statuses') {
                        this.getItemsObject()[key] = data[key];
                    }
                }
            }
        }
    }

    public getItemsObject(): TSmartButtonDataItemsObject {
        return Object.getOwnPropertyDescriptor(this, 'items_object')!.value;
    }

    private setItemsObject(items: TSmartButtonLeadField[], key: TSmartButtonKey): void {
        for (const item of items as TSmartButtonLeadField[]) {
            (this.getItemsObject()[key] as Record<string, typeof item>)[item.id] = item;
            if (item.id.includes('lead[')) {
                this.setOptionsForDefaultField(item);
            }
        }
    }

    private setOptionsForDefaultField(item: TSmartButtonLeadField) {
        switch (item.id) {
            case('lead[MAIN_USER]'):
                item.label = 'Отвтетственный'
                if (item.type_id === 4) {
                    item.options = this.collectSelectOptions(this.users);
                }
                break;
            case('lead[NAME]'):
                item.label = 'Название сделки'
                break;
            case('lead[PIPELINE_ID]'):
                item.label = 'Воронка'
                item.options = this.statuses as Extract<TSmartButtonLeadField, {
                    type_id: 77,
                    options: TStatusSelectOptionsParameters<TStatusSelectOptionsPipeline>
                }>;
                break;
            case('lead[PRICE]'):
                item.label = 'Price'
                break;
            case('lead[STATUS]'):
                item.label = 'Статус'
                if (item.type_id === 77) {
                    item.options = this.statuses as Extract<TSmartButtonLeadField, {
                        type_id: 77,
                        options: TStatusSelectOptionsParameters<TStatusSelectOptionsPipeline>
                    }>;
                }
                break;
        }
    }

    private collectSelectOptions(options: TSmartButtonLeadField[]): TAmoOptionsSelect {
        let collectAmoOptionsSelect: TAmoOptionsSelect = {};
        for (const key in options) {
            collectAmoOptionsSelect[key] = {
                ID: +options[key].value,
                VALUE: options[key].label,
                SORT: options[key].sort,
            }
        }
        return collectAmoOptionsSelect;
    }
}
