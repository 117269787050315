import { patchState, signalStoreFeature, withMethods } from "@ngrx/signals";
import { removeEntity, setAllEntities, updateEntity } from "@ngrx/signals/entities";
import { inject } from "@angular/core";
import { rxMethod } from "@ngrx/signals/rxjs-interop";
import { tapResponse } from "@ngrx/operators";
import { exhaustMap, filter, pipe, Subscription, tap } from "rxjs";
import { ApiService } from "../../../../../api/services";
import { DataCacheDbController } from "../../../../script/data-cache-db.controller";
import { TDataCacheDB } from "../../../../script/data-cache-db.types";
import {
    SmartButtonsCreateRequest,
    SmartButtonsResponse
} from "../../../../../api/models";
import { ApiSmartButtonsIdPatch$Params } from "../../../../../api/fn/operations/api-smart-buttons-id-patch";
import { PostMessageService } from "../../../../../global-events/PostMessage/post-message.service";
import { SmartButtonData } from "../../popups/models/classes/helpers/smart-button-data";
import { amoFieldTypes } from "../../popups/models/enums/enum-smart-buttons";
import { TSmartButtonLeadField } from "../../popups/models/types/smart-button-settings.types";

export function withSsmartButtonsApi() {
    return signalStoreFeature(
        withMethods((store: any,
                     apiService: ApiService = inject(ApiService),
                     postMessageService: PostMessageService = inject(PostMessageService)
        ) => ({
            loadSmartButtons: rxMethod<void>(
                pipe(
                    exhaustMap(() => {
                        let dataCacheDbController = new DataCacheDbController();
                        dataCacheDbController.readDb('apiSmartButtons').then((obj: TDataCacheDB) => {
                            if (obj?.id && obj.id === 'apiSmartButtons') {
                                patchState(store, setAllEntities(JSON.parse(obj.text)));
                                patchState(store, { isLoaded: true });
                            }
                        });
                        dataCacheDbController.readDb('smart_button_data').then((obj: TDataCacheDB) => {
                            if (obj?.id && obj.id === 'smart_button_data') {
                                patchState(store, { smartButtonData: (JSON.parse(obj.text)) });
                            }
                        });
                        postMessageService.sendMessage({ key: 'robocode_smart_buttons_settings_get_data' });
                        let getPostMessageSubscription: Subscription = postMessageService.getPostMessageEvents().subscribe(event => {
                            if (event.data && event.data?.key === "smart_buttons_data") {
                                let smartButtonData: SmartButtonData = new SmartButtonData(event.data.values);
                                smartButtonData.leadFields = smartButtonData.leadFields.filter((leadField: TSmartButtonLeadField) => {
                                    return amoFieldTypes[leadField.type_id];
                                });
                                dataCacheDbController.writeDb(smartButtonData, 'smart_button_data');
                                patchState(store, { smartButtonData: (smartButtonData) });
                            }
                            getPostMessageSubscription.unsubscribe();
                        });
                        return apiService.apiSmartButtonsGet().pipe(
                            tap({
                                next: (smartButtons: SmartButtonsResponse[]) => {
                                    dataCacheDbController.writeDb(smartButtons, 'apiSmartButtons');
                                    patchState(store, setAllEntities(smartButtons));
                                    patchState(store, { isLoaded: true });
                                },
                                error: console.error,
                            })
                        );
                    })
                ),
            ),
            setEntitiesLastUpdated: () => {
                let dataCacheDbController = new DataCacheDbController();
                dataCacheDbController.readDb('apiSmartButtons').then((obj: TDataCacheDB) => {
                    if (obj?.id && obj.id === 'apiSmartButtons') {
                        patchState(store, setAllEntities(JSON.parse(obj.text)));
                        patchState(store, { isLoaded: true });
                    }
                });
                return;
            },
            createSmartButton: (smartButton: SmartButtonsCreateRequest) => {
                return apiService.apiSmartButtonsPost({ body: smartButton }).pipe(
                    tapResponse({
                        next: (smartButtonsResponse: SmartButtonsResponse) => {
                            console.log(smartButtonsResponse);
                        },
                        error: console.error
                    })
                )
            },
            patchUpdateSmartButton: (smartButtonPatch: ApiSmartButtonsIdPatch$Params) => {
                if (!smartButtonPatch || !smartButtonPatch.id || !smartButtonPatch.body) {
                    console.log(smartButtonPatch);
                    throw new Error('Invalid parameters for patchIdSmartButton');
                }

                return apiService.apiSmartButtonsIdPatch(smartButtonPatch).pipe(
                    tapResponse({
                        next: (smartButtonsResponse: SmartButtonsResponse) => {
                            patchState(store, updateEntity({
                                id: smartButtonPatch.id,
                                changes: { ...smartButtonsResponse }
                            }));
                        },
                        error: console.error,
                    })
                );
            },
            patchIdSmartButton: rxMethod<ApiSmartButtonsIdPatch$Params>(
                pipe(filter((smartButtonPatch: ApiSmartButtonsIdPatch$Params) => !!smartButtonPatch && !!smartButtonPatch.id && !!smartButtonPatch.body), exhaustMap((smartButtonPatch: ApiSmartButtonsIdPatch$Params) => {
                    return apiService.apiSmartButtonsIdPatch(smartButtonPatch).pipe(
                        tapResponse({
                            next: (smartButtonsResponse: SmartButtonsResponse) => {
                                patchState(store, updateEntity({
                                    id: smartButtonPatch.id,
                                    changes: {...smartButtonsResponse}
                                }),)
                            },
                            error: console.error
                        })
                    )
                }))
            ),
            deleteSmartButton: rxMethod<string>(
                pipe(filter((smartButtonId: string) => !!smartButtonId), exhaustMap((smartButtonId: string) => {
                    return apiService.apiSmartButtonsIdDelete({ id: smartButtonId }).pipe(
                        tapResponse({
                            next: () => {
                                patchState(store, removeEntity(smartButtonId));
                            },
                            error: console.error
                        })
                    )
                }))
            ),
        })),
    )
}
